import { isoDate } from '@/utils/get-date'
import { canUseTvp, getPointName, isPointBasedProduct } from '@/constants/product'
import { BookingTypeIds, isBookingTypeAllowedToChange, isBookingTypeFixedRight } from '@/constants/booking-type'

export const selectedBookingMixinMounted = {
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadBookingDetail', { id: this.id })
      }, {
        BOOKING_CANNOT_READ_FOR_CLIENT_ROLE: () => {
          this.$toast.warning(this.$t('text.notHavePermissionViewReservation').toString())
          this.$router.back()
        },
        BOOKING_NOT_FOUND: () => {
          this.$toast.warning(this.$t('text.specifiedCouldNotBeFound').toString())
          this.$router.back()
        }
      })
    })
  }
}

export const selectedBookingComputed = {
  methods: {
    completeChange (options = { completed: true }) {
      this.$store.commit('setChangeCompleted', {
        message: options?.message,
        buttons: options?.buttons,
        completed: options?.completed !== false
      })
    }
  },
  computed: {
    changeCompleted () {
      return this.$store.state.usageHistory.changeCompleted
    },
    changeMessage () {
      return this.$store.state.usageHistory.changeMessage
    },
    changeButtons () {
      return this.$store.state.usageHistory.changeButtons
    },
    id () {
      return Number(this.$route.params.id)
    },
    booking () {
      return this.$store.getters.selectedBooking
    },
    bookingTypeId () {
      return this.booking?.bookingTypeId
    },
    contract () {
      return this.booking?.contract
    },
    parkingCount () {
      if (!this.booking) return 0
      return this.booking.parkingRequestedNumber ?? this.booking.parkings.length
    },
    hasOptionalCleaningFee () {
      return this.booking?.optionalCleaningTotal.totalJpy > 0
    },
    isFutureBooking () {
      return this.booking?.checkInDate > isoDate(new Date())
    },
    canModify () {
      return this.booking?.canModifyByClient && isBookingTypeAllowedToChange(this.bookingTypeId)
    },
    canBookParking () {
      return this.booking?.canBookParking
    },
    canBookRentalItem () {
      return this.booking?.canBookRentalItem
    },
    isFixedRightBooking () {
      return isBookingTypeFixedRight(this.bookingTypeId)
    },
    cancelledAt () {
      return this.booking?.cancelledAt
    },
    facility () {
      return this.booking?.room.roomType.facility
    },
    acceptsSameDayBooking () {
      return this.facility?.acceptsSameDayBooking
    },
    roomType () {
      return this.booking?.room.roomType
    },
    isPointBasedProduct () {
      if (!this.contract) return null
      return isPointBasedProduct(this.contract.productTypeId)
    },
    priceUnit () {
      if (!this.contract) return ''
      return this.isPointBasedProduct ? this.$t('unit.point') : this.$t('common.yen')
    },
    pointName () {
      if (!this.contract) return null
      return getPointName(this.contract.productTypeId)
    },
    priceUnitShort () {
      if (!this.contract) return ''
      return this.isPointBasedProduct ? 'pt' : this.$t('common.yen')
    },
    canUseTvp () {
      if (!this.contract) return false
      return canUseTvp(this.contract.productTypeId)
    },
    currentAccommodationFee () {
      if (!this.booking) return null
      const total = this.booking.accommodationFeeTotal
      const onlyAccommodationFeeTotal = this.booking.onlyAccommodationFeeTotal
      return {
        jpy: total.totalJpy,
        sp: total.totalSp,
        fp: total.totalFp,
        onlyAccommodationFeeJpy: onlyAccommodationFeeTotal.totalJpy
      }
    },
    accommodationFeeName () {
      switch (this.bookingTypeId) {
        case BookingTypeIds.VS:
          return 'バケーションスタイル宿泊料'
        case BookingTypeIds.MO:
          return 'マスターズオプション宿泊料'
        default:
          return '宿泊料'
      }
    },
    acceptsPet () {
      return this.booking?.room.roomType.acceptsPet
    },
    showOldPetInfo () {
      return [].includes(this.facility.id) // since TO2020-847 all facility should show the new ui
    },
    representativeGuest () {
      return this.booking?.guests.find(g => g.representative)
    },
    isSubMemberCreated () {
      return !!this.booking?.bookingGroup.createdSubMemberId
    },
    prePayment () {
      return this.booking?.prePaymentInfo?.prepaymentAmount
    },
    remainingPrePayment () {
      return this.booking?.prePaymentInfo?.remainingPoint
    }
  }
}

export default {
  ...selectedBookingMixinMounted,
  ...selectedBookingComputed
}
